<template>
    <div style="height: 500px">
        <canvas ref="chart" />
    </div>
</template>

<script>
import Chart from 'chart.js';
import Operation from '../utils/operation';

export default {
    props: {
        // Structure of dynamic prop.
        // [
        //     {
        //         name: 'Dataset 1',
        //         data: [
        //             { name: 'Label A', value: 3 }
        //             { name: 'Label B', value: 20 }
        //         ]
        //     },
        //     {
        //         name: 'Dataset 2',
        //         data: [
        //             { name: 'Label A', value: 10 }
        //             { name: 'Label C', value: 15 }
        //         ]
        //     }
        // ]
        datasets: {
            type: Array(Object),
            required: true,
            default: () => [{}]
        }
    },

    data() {
        return {
            buffer: {
                colors: [
                    '#15bdc7',
                    '#4b626c'
                ]
            },

            data: this.datasets,
            chart: null
        }
    },

    watch: {
        datasets(newVal) {
            this.prepareDatasets(newVal)
        }
    },

    mounted() {
        const ctx = this.$refs.chart.getContext('2d');
        ctx.height = 500;
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: [],
                datasets: []
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                maintainAspectRatio: false
            }
        });

        this.prepareDatasets(this.data);
    },

    methods: {
        updateChartData(data, scale) {
            this.chart.data = data;
            this.chart.options.scales.yAxes[0] = {
                ticks: {
                    beginAtZero: true,
                    suggestedMax: scale //new scale
                }
            };

            this.chart.update();
        }, 

        prepareDatasets(arr) {
            const data = {
                labels: [],
                datasets: []
            };
            const values = [];
            arr.forEach((ds, i) => {
                if (ds == null)
                    return;

                const obj = {
                    label: ds.name,
                    data: [],
                    backgroundColor: this.buffer.colors[i],
                    borderWidth: 1
                };
                ds.data.forEach(da => {
                    let i = -1;
                    if (data.labels.indexOf(da.name) == -1) { //Only if the label is not repeated
                        i = data.labels.push(da.name) - 1;
                    } else {
                        i = data.labels.indexOf(da.name);
                    }

                    obj.data[i] = Number(da.value.toFixed(2));

                    values.push( Number(da.value.toFixed(2)) );
                });
                data.datasets.push(obj);
            });

            // Scaling the graph to the mean of all values
            // to give space between the top margin and bars
            const op = new Operation('mean', { decimals: 2 });
            const mean = op.compute(values);

            this.updateChartData(data, mean * 2);
        }
    }
}
</script>